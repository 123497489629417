import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'
import { getGroups } from '@api'

export default function useGroupsList() {
  const groupListTable = ref([])
  const { hasActionPermission, hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()
  const { t } = useUtils()
  const tableColumns = computed(() => [
    { text: t('users.name').toUpperCase(), value: 'name', show: true },
    { text: t('users.email').toUpperCase(), value: 'email', show: true },
    { text: t('users.contact').toUpperCase(), value: 'phone', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(34, 36, 37),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const totalGroupListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const groupTotalLocal = ref([])

  // fetch data
  const fetchGroups = async () => {
    flag.value = true
    const responseGroup = await getGroups()

    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    filters.value = filterOption

    // group.signer_name.toLowerCase().includes(searchQuery.value) ||
    const filteredData = responseGroup.data.filter(
      group =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        group.name.toLowerCase().includes(searchQuery.value) ||
        group.email.toLowerCase().includes(searchQuery.value),
    )

    groupListTable.value = filteredData
    totalGroupListTable.value = filteredData.length
    groupTotalLocal.value = null
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchGroups()
    }, time)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    updateFilter('views-group-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch(300)
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveGroupStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveGroupStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    groupListTable,
    computedTableColumns,
    searchQuery,
    totalGroupListTable,
    loading,
    options,
    filters,
    groupTotalLocal,

    fetchGroups,
    getDataBySearch,
    resolveGroupStatusVariant,
    resolveGroupStatusText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
